import React, { memo, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { displayTime } from '../../../utils/time';
import { TimeSlot } from '../../../types';
import { defaultTimeFormat } from '../../../utils/constants';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
  TimeSlotButton,
} from '../../../styling/buttons';
import '../components/styling/card-styling.scss';
import Cookie from 'js-cookie';
import useSelectProfileData from '../../../hooks/useSelectProfileData';
import MenteeDetailsModal from '../../../pages/NewCallDetails/menteeDetailsModal';
import moment from 'moment';
import BusinessHealthTooltip from '../../../atoms/BusinessHealthTooltip.tsx';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from '../../../apiHooks/sessionTitle';
interface Props {
  appointment: any;
  timeZoneString: string;
  type: string;
  key: number;
  setDisplayEventDetails?: (arg0: any) => void;
}

const NewRequestedCard = ({
  appointment,
  timeZoneString,
  type,
  setDisplayEventDetails,
}: Props) => {

  const { volunteerProfile } = useSelectProfileData();
  const myVolunteerId = volunteerProfile?.id;
  const navigate = useNavigate();
  const {
    first_name,
    profile_picture,
    name: orgName,
    business_health,
  } = appointment.organisation || {};
  const timeSlots = useMemo(() => appointment?.time_slots, [appointment]);
  const id = appointment.id;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const hasAlreadyProposedNewTime = appointment?.time_slots.find(
    ({ status, volunteer }: TimeSlot) =>
      status === 'time_slot_proposed' && volunteer === myVolunteerId
  );

  const sessionTitle = useSelector(selectSessionTitle);
  return (
    <div className="dashboard-box">
      <div className="requested-paper-box">
        <Paper key={id} elevation={0}>
          <div>
            <div className="tags-container">
              <div className="rectangle-icon-container">
                <div className="rectangle-icon">
                  <div className="rectangle-icon-text">Request</div>
                </div>
              </div>
              {business_health ? (
                <BusinessHealthTooltip business_health={business_health} />
              ) : null}
            </div>
            <div className="request-card__container">
              <div
                onClick={() => setIsModalOpen(true)}
                className="profile-photo-card"
                style={{
                  cursor: 'pointer',
                  backgroundImage: profile_picture
                    ? `url(${profile_picture})`
                    : 'url(/avatar.png)',
                }}
              ></div>
              <div className="dashboard-card-header-info">
                <div className="dashboard-card-title">
                  <div className="dashboard-card-name">
                    {sessionTitle} with
                    <div className="request-paper-first-name">
                      {first_name ? first_name : 'Profile not found'}
                    </div>
                  </div>
                </div>
                <div className="request-paper-organisation">{orgName}</div>
                <div className="request-paper-topic">
                  {appointment.top_priority_name ||
                    appointment.top_priority.name}
                </div>
                {hasAlreadyProposedNewTime ? (
                  <div>
                    <div className="request-help-text">
                      You have proposed a different time
                    </div>
                    <div className="request-help-text">
                      {displayTime(
                        hasAlreadyProposedNewTime.start_time,
                        timeZoneString,
                        `DD MMM ${defaultTimeFormat}`
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="request-paper-slots"
                      style={{ marginBottom: '15px' }}
                    >
                      Select a slot below
                    </div>
                    {type !== 'communityRequests' ? (
                      <div className="request-paper-time-slots">
                        {timeSlots
                          .filter(
                            (timeSlot: {
                              volunteer: null;
                              status: string;
                              start_time: string;
                            }) =>
                              timeSlot.volunteer === null &&
                              moment(timeSlot.start_time) > moment() &&
                              timeSlot.status === 'time_slot_pending'
                          )
                          .map((timeSlot: TimeSlot, index: number) => (
                            <TimeSlotButton
                              sx={{ color: '#333' }}
                              key={index}
                              variant="outlined"
                              onClick={() => {
                                Cookie.set(
                                  'timeSlotId',
                                  timeSlot.id.toString()
                                );

                                return navigate(
                                  `/call-details?type=requests&cid=${
                                    appointment.id
                                  }&time_slot=${timeSlot.id.toString()}`
                                );
                              }}
                            >
                              {displayTime(
                                timeSlot.start_time,
                                timeZoneString,
                                `DD MMM ${defaultTimeFormat}`
                              )}
                            </TimeSlotButton>
                          ))}
                      </div>
                    ) : (
                      <div className="request-paper-time-slots">
                        {timeSlots.filter(
                          (timeSlot: {
                            volunteer: null;
                            status: string;
                            start_time: string;
                          }) =>
                            timeSlot.volunteer === null &&
                            moment(timeSlot.start_time) > moment() &&
                            timeSlot.status === 'time_slot_pending'
                        ).length > 0 ? (
                          timeSlots
                            .filter(
                              (timeSlot: {
                                volunteer: null;
                                status: string;
                                start_time: string;
                              }) =>
                                timeSlot.volunteer === null &&
                                moment(timeSlot.start_time) > moment() &&
                                timeSlot.status === 'time_slot_pending'
                            )
                            .map((timeSlot: TimeSlot, index: number) => (
                              <TimeSlotButton
                                key={index}
                                sx={{ color: '#333' }}
                                variant="outlined"
                                onClick={() => {
                                  Cookie.set(
                                    'timeSlotId',
                                    timeSlot.id.toString()
                                  );
                                  if (setDisplayEventDetails)
                                    setDisplayEventDetails(appointment);
                                  else return null;
                                }}
                              >
                                {displayTime(
                                  timeSlot.start_time,
                                  timeZoneString,
                                  `DD MMM ${defaultTimeFormat}`
                                )}
                              </TimeSlotButton>
                            ))
                        ) : (
                          <div
                            className="request-paper-slots"
                            style={{ marginBottom: '15px' }}
                          >
                            Sorry, this request has expired
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className="request-paper-button-wrapper">
                  <div className="requested-dashboard-button-container">
                    {first_name ? (
                      <DashboardFilledButton
                        variant="contained"
                        onClick={() => {
                          if (type !== 'communityRequests')
                            return navigate(
                              `/call-details?type=requests&cid=${appointment.id}`
                            );
                          else if (setDisplayEventDetails)
                            return setDisplayEventDetails(appointment);
                          else return null;
                        }}
                      >
                        View Details
                      </DashboardFilledButton>
                    ) : null}
                  </div>
                  {type !== 'communityRequests' ? (
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ minWidth: '180px', color: '#333333' }}
                        disabled={hasAlreadyProposedNewTime !== undefined}
                        onClick={() => {
                          navigate(
                            `/call-details?type=requests&cid=${appointment.id}&action=propose_new_time`
                          );
                        }}
                      >
                        Propose New Time
                      </DashboardEmptyButton>
                    </div>
                  ) : null}{' '}
                  {type !== 'communityRequests' ? (
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ maxWidth: '100px', color: '#ff5f5f' }}
                        onClick={() =>
                          navigate(
                            `/decline-call?code=${appointment.invite.code}`
                          )
                        }
                      >
                        Decline
                      </DashboardEmptyButton>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>

      {isModalOpen ? (
        <MenteeDetailsModal
          isOpen={isModalOpen}
          event={appointment}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
};

export default memo(NewRequestedCard);
